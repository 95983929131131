<template>
  <div
    :class="[
      platform.includes('ios') ? 'mobile' : '',
      platform.includes('android') ? 'mobile-md' : '',
      platform.includes('mobileweb') ? 'desktop' : '',
      platform.includes('desktop') ? 'desktop' : ''
    ]"
  >
    <ion-toolbar>
      <ion-buttons
        slot="start"
      >
        <ion-menu-toggle>
          <ion-avatar
            v-if="$store.state.userRole !== 'Landlord'"
            class="profile-image"
          >
            <ion-img
              v-if="profileImage"
              :src="profileImage"
            />
            <ion-img
              v-else
              :src="require('../../assets/images/default.png')"
            />
          </ion-avatar>
          <div
            v-else
            style="padding-left: 20px;"
          >
            <ion-icon
              style="font-size: 25px;"
              :icon="menuOutline"
            />
          </div>
        </ion-menu-toggle>
      </ion-buttons>
      <!-- <ion-title v-if="currentRouteName !== 'MainView' && $store.state.userRole !== 'Landlord'">
        {{ currentRouteName }}
      </ion-title> -->
      <ion-spinner
        v-show="gatewayProgress"
        slot="end"
        color="primary"
        style="max-width: 20px; padding-right: 5px;"
        name="crescent"
      />
      <i
        v-if="userHasNoGateway"
        slot="end"
        tappable
        style="font-size: 25px;"
        class="mdi mdi-alert-circle-outline text--primary"
        @click="openAddGatewayAlert"
      />
      <i
        v-if="gatewayNotActive"
        slot="end"
        tappable
        style="font-size: 25px;"
        class="mdi mdi-alert-circle-outline text--primary"
        @click="gatewayNotActiveAlert"
      />
      <ion-buttons
        v-if="$store.state.userRole !== 'Landlord'"
        slot="end"
      >
        <ion-button
          class="notification"
          color="transparent"
          @click="openModal()"
        >
          <i
            class="material-icons text--white"
            style="font-size: 25px;"
          >notifications</i>
          <ion-badge
            v-if="notificationCount"
            class="text--dark notification-count"
          >
            {{ notificationCount }}
          </ion-badge>
        </ion-button>
      </ion-buttons>
      <ion-buttons
        v-else
        slot="end"
      >
        <ion-button
          class="notification"
          color="transparent"
          @click="openModal()"
        >
          <i class="material-icons text--white">notifications</i>
          <ion-badge
            v-if="landlordNotificationCount"
          >
            {{ landlordNotificationCount }}
          </ion-badge>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </div>
</template>

<script>
import { modalController, alertController } from '@ionic/vue'
import NotificationsModal from '../NotificationsModal.vue'

import {
  menuOutline
} from 'ionicons/icons'

export default {
  components: {
  },
  data () {
    return {
      userEmail: '',
      menuOutline,
      gatewayNotActive: false
    }
  },
  computed: {
    platform () {
      return this.$store.state.platform
    },
    profileImage () {
      return this.$store.state.profileImage
    },
    notificationCount () {
      const notifications = this.$store.state.notification
      let count = 0
      notifications.forEach((element) => {
        if (!element.read) { count++ }
      })
      return count
    },
    landlordNotificationCount () {
      const landlordNotifications = this.$store.state.landlordNotifications
      return landlordNotifications.length
    },
    gatewayProgress () {
      return this.$store.state.gatewayProgress
    },
    userHasNoGateway () {
      return this.$store.state.userHasNoGateway
    },
    isGatewayActive () {
      return this.$store.state.userProperties.length ? this.$store.state.userProperties[0].gatewayActive : undefined
    },
    isGatewayPresent () {
      return this.$store.state.userProperties.length ? this.$store.state.userProperties[0].propertyGateway : false
    }
  },
  watch: {
    isGatewayActive (value) {
      if (value === false && this.isGatewayPresent) {
        this.gatewayNotActive = true
      } else if (value === true && this.gatewayNotActive === true) {
        this.gatewayNotActive = false
      }
    }
  },
  created () {
  },
  methods: {
    openModal () {
      return modalController
        .create({
          component: NotificationsModal,
          cssClass: 'devices-modal',
          swipeToClose: false,
          showBackdrop: true,
          backdropDismiss: true,
          componentProps: {
            modalTitle: 'Notifications',
            close: () => this.closeModal()
          }
        })
        .then(m => m.present())
    },
    closeModal () {
      modalController.dismiss()
    },
    async openAddGatewayAlert () {
      const alert = await alertController
        .create({
          cssClass: 'remove-device',
          header: this.$t('Gateway not added'),
          message: this.$t('Would you like to add gateway now?'),
          buttons: [
            {
              text: this.$t('Later'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
              }
            },
            {
              text: this.$t('Add now'),
              handler: () => {
                this.$router.push('app-intro')
              }
            }
          ]
        })
      return alert.present()
    },
    async gatewayNotActiveAlert () {
      const alert = await alertController
        .create({
          cssClass: 'remove-device',
          header: this.$t('Gateway disconnected'),
          message: this.$t('Your gateway seems to be disconected, please check that is plugged in and flashing blue'),
          buttons: [
            {
              text: this.$t('close'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
              }
            },
            {
              text: this.$t('Help'),
              handler: () => {
                this.$router.push('help-with-gateway')
              }
            }
          ]
        })
      return alert.present()
    }
  }
}
</script>

<style lang="scss" scoped>
  ion-header {
    ion-toolbar {
      // padding:10px 0;
      position: fixed;
      z-index: 9;
      width: 100%;
      --border-style: unset !important;
    }
    ion-title {
      font-weight: 300;
      letter-spacing: 0.6px;
    }
    .notification {
        padding-right: 10px;
    ion-badge {
      position: absolute;
      top: 0px;
      font-size: 10px;
      padding: 4px;
      right: -5px;
    }
    }
  }
  ion-header,
  ion-toolbar,
  ion-content {
    --background: none;
    color: #fff;

    ion-icon {
      color: #fff;
    }
  }
  ion-avatar {
    max-width: 40px;
    max-height: 40px;
  }
  .profile-image {
    margin-left: 10px;
    margin-top: 5px;
    border: 2px solid #FFD4A4;
    border-radius: 50%;
  }
  .mobile {
    min-height: 100px;
  }
  .mobile-md {
    min-height: 80px;
  }
  .desktop {
    min-height: 60px;
  }
  .notification-count {
    border-radius: 30%;
  }
 @media (max-height: 800px) {
  .mobile {
    min-height: 60px;
  }
  .mobile-md {
    min-height: 60px;
  }
}
</style>