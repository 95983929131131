<template>
  <div :class="userRole === 'Landlord' ? 'background-modal-landlord' : 'background--modal'" />
  <ion-header class="ion-no-border">
    <ion-toolbar color="transparent">
      <ion-button
        slot="start"
        fill="clear"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="dismissModal()"
      >
        <img src="../assets/images/arrow-go-back-line.svg">
      </ion-button>
      <ion-title class="text--white text-18">
        {{ $t('notifications') }}
      </ion-title>
      <div
        slot="end"
        tappable
        class="pr-10"
        @click="removeAllNotifications"
      >
        <i
          class="mdi mdi-delete-forever text--primary"
          style="font-size: 25px;"
        />
      </div>
    </ion-toolbar>
  </ion-header>
  <ion-content
    class="ion-padding"
  >
    <div v-if="userRole !== 'Landlord'">
      <div>
        <transition-group
          name="list"
        >
          <div
            v-if="Object.keys(notifications).length"
            class="notification-outer"
          >
            <div
              v-for="(notificationList, key, index) in notifications"
              :key="index"
            >
              <ion-list
                v-for="notification in notificationList"
                :key="notification"
                class="text-left"
              >
                <ion-item-sliding>
                  <ion-item
                    class="text--white ion-no-padding"
                    lines="none"
                    color="transparent"
                  >
                    <ion-label>
                      <div class="text-18 text--gray">
                        <span class="mdi mdi-antenna text--primary"> <span class="text--gray text-14 pl-10">{{ displayTime(notification.time) }}</span></span>
                      </div>
                      <div
                        class="text-14"
                      >
                        <span
                          v-if="notification.type !== 'alarmStatus'"
                          :class="notification.read ? '' : 'text--bold text--primary'"
                          style="transition: 0.1s"
                        >
                          {{ notification.warningMessage }} {{ $t('in') }} {{ propertyRoom(notification.propertyRoomId) }}
                        </span>
                        <span v-else>
                          {{ notification.warningMessage }}
                        </span>
                      </div>
                    </ion-label>
                  </ion-item>
                  <ion-item-options
                    side="end"
                  >
                    <ion-item-option
                      color="danger"
                    >
                      <ion-icon
                        style="color: white;"
                        size="large"
                        :icon="closeOutline"
                        @click="removeNotification(notification.id)"
                      />
                    </ion-item-option>
                  </ion-item-options>
                </ion-item-sliding>
              </ion-list>
            </div>
          </div>
          <ion-text v-if="!Object.keys(notifications).length">
            <span class="text--gray">{{ $t('noNotifications') }}</span>
          </ion-text>
        </transition-group>
      </div>
    </div>
    <div v-else>
      <transition-group
        name="list"
      >
        <div
          v-if="Object.keys(landlordNotifications).length"
          class="notification-outer"
        >
          <div
            v-for="(notificationList, key, index) in landlordNotifications"
            :key="index"
          >
            <ion-list
              v-for="notification in notificationList"
              :key="notification"
              class="text-left"
              @click="$router.push(`/landlord-properties/${propertyMapper(notification.propertyId).buildingId}`); dismissModal()"
            >
              <ion-item
                class="text--white ion-no-padding"
                color="transparent"
              >
                <ion-label>
                  <div class="text-18 text--gray">
                    <span class="mdi mdi-antenna text--primary"> <span class="text--gray text-14 pl-10">{{ displayTime(notification.time) }}</span></span>
                  </div>
                  <h3 class="text-16">
                    {{ notification.warningMessage }} in property {{ propertyMapper(notification.propertyId).message }}
                  </h3>
                </ion-label>
                <i
                  slot="end"
                  class="mdi mdi-close text--danger"
                  style="font-size: 25px;"
                  @click="removeLandlordNotification(notification.id); $event.stopPropagation();"
                />
              </ion-item>
            </ion-list>
          </div>
        </div>
        <ion-text v-if="!Object.keys(landlordNotifications).length">
          Nothing here now....
        </ion-text>
      </transition-group>
    </div>
  </ion-content>
</template>

<script>
// import { Plugins } from '@capacitor/core'
import { closeOutline } from 'ionicons/icons'
import { defineComponent } from 'vue'
import * as moment from 'moment'
import { PushNotifications } from '@capacitor/push-notifications'

export default defineComponent({
  name: 'Notifications',
  props: {
    modalTitle: { type: String, default: 'Modal' },
    close: { type: Function, default: null }
  },
  data () {
    return {
      closeOutline,
      content: 'Content',
      selectedView: '1',
      moment: moment

    }
  },
  computed: {
    notificationCount () {
      return this.$store.state.notification.length
    },
    userRole () {
      return this.$store.state.userRole
    },
    notifications () {
      const notifications = this.$store.state.notification
      notifications.sort(function () {
        return new Date(notifications.createdAt) - new Date(notifications.createdAt)
      })

      const formatedNotifications = {}
      notifications.forEach(notification => {
        const date = moment(notification.time, 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY')
        // console.log('notification ' + new Date().getTime(), date, notification.time)
        if (formatedNotifications[date] === undefined) {
          formatedNotifications[date] = []
        }
        formatedNotifications[date].push(notification)
      })
      console.log(formatedNotifications)
      return formatedNotifications
    },
    landlordNotifications () {
      const notifications = this.$store.state.landlordNotifications
      notifications.sort(function () {
        return new Date(notifications.createdAt) - new Date(notifications.createdAt)
      })

      const formatedNotifications = {}
      notifications.forEach(notification => {
        const date = moment(notification.time, 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY')
        // console.log('notification ' + new Date().getTime(), date, notification.time)
        if (formatedNotifications[date] === undefined) {
          formatedNotifications[date] = []
        }
        formatedNotifications[date].push(notification)
      })

      return formatedNotifications
    },
    platform () {
      return this.$store.state.platform
    }
  },
  mounted () {
    this.removeGoogleNot()
  },
  methods: {
    dismissModal () {
      this.$store.dispatch('allNotificationsRead')
      this.close()
    },
    propertyRoom (roomId) {
      const rooms = this.$store.state.propertyRooms
      const room = rooms.find(element => element.id === roomId)

      return room ? room.roomName : 'N/A'
    },
    removeAllNotifications () {
      this.$store.dispatch('removeAllNotification')
    },
    propertyMapper (propertyId) {
      const buildings = this.$store.state.landlordBuildings
      const properties = this.$store.state.landlordProperties
      const property = properties.filter(item => item.id === propertyId)
      const building = buildings.filter(item => item.id === property[0].buildingId)
      return { message: `${building[0].buildingName} in aparment ${property[0].propertyName}`, buildingId: property[0].buildingId }
    },
    removeNotification (id) {
      this.$store.dispatch('removeNotification', id)
    },
    removeLandlordNotification (id) {
      this.$store.dispatch('removeLandlordNotification', id)
    },
    displayTime (date) {
      const momentObj = moment(date, 'DD/MM/YYYY HH:mm:ss')
      if (momentObj.format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')) {
        return momentObj.fromNow()
      } else {
        return momentObj.format('DD/MM/YYYY hh:mm A')
      }
    },
    removeGoogleNot () {
      if (this.platform.includes('capacitor')) {
        PushNotifications.removeAllDeliveredNotifications()
      }
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/theme/_colors';

.tag-heading {
  position: relative;
    z-index: 1;
    overflow: hidden;
    text-align: center;
    color: #bbaeae;
    font-family: arial;
    margin-top: 0;
    font-size: 16px;
    margin-bottom: 20px;
    font-weight: 300;
}
.tag-heading:before, .tag-heading:after {
    position: absolute;
    top: 51%;
    overflow: hidden;
    width: 48%;
    height: 1px;
    content: '\a0';
    background-color: #504e4e;
    margin-left: 2%;
}
.tag-heading:before {
    margin-left: -50%;
    text-align: right;
}
ion-list {
  background-color: transparent;
}
ion-item {
  padding: 0!important;
  margin-bottom: 5px;
  p{
      color:#D9E1D980 !important;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 120%;
span.mdi {
    color: #FFD4A4;
    font-size: 18px;
    margin-left: 0;
    margin-right: 5px;
    vertical-align: bottom;
}
span {
    font-size: 13px;
    margin-left: 4px;
}
  }
  h3{
      color:#fff;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 120%;
margin-top:6px;
  }
}

ion-item-options{
    border-bottom-style:none;
    border-bottom-color:transparent;
}
ion-item-option {
    width: 25px !important;
    ion-icon{
    --ion-color-base: #000000 !important;
    font-size:12px;
    width: 22px;
}
}

ion-header {
  color: white;
}
ion-segment {
  --background: transparent;
  min-height: 44px;

ion-segment-button {
  --color-checked: #{colors(primary, base)};
  --indicator-color: transparent !important;
  --indicator-color-checked: transparent !important;
  --indicator-box-shadow: transparent!important;
  --border-width:0px;
}
.segment-button-checked {
  font-weight: medium;
  position: relative;
  font-weight: medium;
}
.segment-button-checked::after{
  content : "";
  position: absolute;
  left: 25%;
  bottom: 0px;
  width: 50%;
  border-bottom : solid 3px colors(primary, base);
}
}
.list-item {
  display: inline-block;
  margin-right: 10px;
}
.list-enter-active, .list-leave-active {
  transition: all 0.4s;
}
.list-enter, .list-leave-to {
  opacity: 0;
  transform: translateX(-400px);
}
</style>
